/***************
 Mobile First 
***************/

/***************
 Header
***************/

header {
    /* TODO: Dynamic background color */
    background: var(--background);
    text-align: center;
    position: sticky;
    z-index: 9999;
    width: 100%;
    position: fixed;
    padding: 25px 40px;
}

header.about {
    background: var(--background-about);
}

header.get-help {
    background: var(--background-get-help);
}

header.give-help {
    background: var(--background-give-help);
}

header.donate {
    background: var(--background-donate);
}

header.about nav {
    background: var(--nav-background-about);
}

header.get-help nav {
    background: var(--nav-background-get-help);
}

header.give-help nav {
    background: var(--nav-background-give-help);
}

header.donate nav {
    background: var(--nav-background-donate);
}

header a.home {
    white-space: nowrap;
    text-decoration: none;
    text-transform: none;
    color: black;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
}

@media screen and (min-width: 950px) {
    header a.long-home {
        display: unset;
    }
    header a.short-home {
        display: none;
    }
}

@media screen and (max-width: 950px) {
    header a.long-home {
        display: none;
    }
    header a.short-home {
        display: unset;
    }
}

/****************************
*            Nav            *
****************************/

nav {
    position: absolute;
    text-align: left;
    left: 0;
    top: 100%;
    height: 100vh;
    width: fit-content;
    padding: 10px 10px 10px 0;
    background-color: var(--background);
    /* Toggle Animation - collapse/expand */
    transform: translateX(-100%);
    transform-origin: left;
    transition: transform 400ms ease-in-out;
    font-size: 18px;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin: 1em;
    font-size: 32px;
}

nav a {
    text-decoration: none;
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* Toggle Animation - Collapse */
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

nav a:hover {
    /* TODO: change hover later */
    font-weight: bolder;
}

.nav-toggle:checked~nav {
    /* Toggle Animation */
    transform: translateX(0);
}

.nav-toggle:checked~nav a {
    /* Animation - Expand */
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
}

nav a.is-active.about-link {
    color: var(--font-color-about);
}

nav a.is-active.get-help-link {
    color: var(--font-color-get-help);
}

nav a.is-active.give-help-link {
    color: var(--font-color-give-help);
}

nav a.is-active.donate-link {
    color: var(--font-color-donate);
}

/* Nav Hamburger */

.nav-toggle {
    display: none;
}

.nav-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 20px;
    height: 100%;
    /* height: 50px; */
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nav-toggle-label span, .nav-toggle-label span::before, .nav-toggle-label span::after {
    position: relative;
    display: block;
    background: black;
    height: 2px;
    width: 2em;
    border-radius: 2px;
}

.nav-toggle-label span::before, .nav-toggle-label span::after {
    content: '';
    position: absolute;
}

.nav-toggle-label span::before {
    bottom: 7px;
}

.nav-toggle-label span::after {
    top: 7px;
}

/***************
 Desktop 
***************/

@media screen and (min-width: 768px) {
    /**************************
    *         Header          *
    **************************/

    header {
        display: grid;
        font-size: 24px;
        line-height: 29px;
    }

    /**************************
    *           Nav           *
    **************************/

    header nav {
        background: none;
        
    }
    header .nav-toggle-label {
        display: none;
    }

    /* nav */
    nav {
        /* The all takes off all the styles on the nav */
        all: unset;
        grid-column: 3/4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        white-space: nowrap;
    }
    nav ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: right;
        gap: 0 calc(0px + (30 - 0) * ((70vw - (var(--page-min-width) * 1px)) / (var(--page-max-width) - var(--page-min-width))));
        margin: 0;
    }

    nav ul li {
        margin: 0;
        font-size: 18px
    }
    
    nav a {
        opacity: 1;
        position: relative;
    }
    /* nav animations */
    nav a::after {
        content: '';
        display: block;
        height: 5px;
        /* TODO: change color to match page font color */
        background: black;
        position: absolute;
        bottom: -.5em;
        left: 0;
        right: 0;
        border-radius: 20px;
        /* Animation */
        transform: scale(0, 1);
        transition: transform ease-in-out 250ms;
    }
    nav a:hover::after {
        /* Animation */
        transform: scale(1, 1);
    }
    nav a.is-active::after {
        /* Animation */
        transform: scale(1, 1);
    }
    nav a.about-link::after {
        background: var(--font-color-about);
    }
    nav a.get-help-link::after {
        background: var(--font-color-get-help);
    }
    nav a.give-help-link::after {
        background: var(--font-color-give-help);
    }
    nav a.donate-link::after {
        background: var(--font-color-donate);
    }
}