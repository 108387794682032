.about a.button-link {
    background-color: #009E78;
}

/******************************** 
Components
********************************/

/* The Neighborhood */

.about #the-neighborhood {
    background-color: #F1FDF7;
}

.about #the-neighborhood .split-wrapper>img {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.about #the-neighborhood .title {
    text-align: left;
    color: #212121;
    margin-bottom: 40px;
}

.about #the-neighborhood .small-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 40px 0;
}

@media screen and (min-width: 768px) {
    .about #the-neighborhood .row-container {
        max-width: 825px;
    }
    .about #the-neighborhood .split-wrapper {
        grid-template-columns: 40fr 60fr;
    }
    .about #the-neighborhood .split-wrapper>img {
        width: 100%;
    }
}

/* About us */

.about #about-us .row-container .split-wrapper .subheader {
    margin-bottom: 60px;
}

@media screen and (min-width: 768px) {
    .about #about-us .row-container .split-wrapper {
        grid-template-columns: 8fr 7fr;
        column-gap: 50px;
    }
    .about #about-us .row-container img {
        /* margin-left: 10px; */
        /* margin-top: -30px; */
        /* margin-bottom: 10px; */
        /* height: 115%; */
        /* width: unset; */
    }
}

/* What is mutual aid */

section#what-is-mutual-aid .fancy-list-wrapper .fancy-list .fancy-list-container {
    grid-column-gap: 4em;
}

@media screen and (min-width: 768px) {
    section#what-is-mutual-aid .fancy-list-wrapper {
        padding-right: 30%;
    }
    section#what-is-mutual-aid .fancy-list-wrapper .subheader {
        padding-right: 10%;
    }
}

@media screen and (max-width: 768px) {

    section#what-is-mutual-aid .fancy-list-wrapper {
        padding-right: 0;
    }
}

/* Where We Act */

.about #where-we-act .row-container {
    display: grid;
}

.about #where-we-act .header1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}

.about #where-we-act p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
}

.about #where-we-act .split-col-img {
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .about #where-we-act .row-container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 100px;
    }
    .about #where-we-act .split-col-img {
        width: 100%;
    }
}

/* Video */

#howItWorks>.row-container>iframe {
    width: 100%;
    aspect-ratio: 16/9;
}

/* Stats Component  */

.about #stats .row-container {
    display: grid;
    row-gap: 30px;
    justify-content: left;
}

.about #stats .stats-items-wrapper {
    display: grid;
    row-gap: 40px;
    margin-top: 60px;
    grid-template-columns: 80%;
    text-align: left;
}

.about #stats .stats-items-wrapper .stats-item {
    display: grid;
    row-gap: 5px;
}

.about #stats .stats-items-wrapper .stats-item .stats-item-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}

.about #stats .stats-items-wrapper .stats-item .stats-item-description {
    font-size: 16px;
    line-height: 26px;
}

.about #stats img.people-holding-trash-svg {
    padding-top: 100px;
    width: 80%;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .about #stats .row-container {
        grid-template-columns: 70%;
    }
    .about #stats .stats-items-wrapper {
        grid-template-columns: auto auto;
        grid-column-gap: 30px;
    }
    .about #stats .people-holding-trash-svg {
        display: none;
    }
}

/* Stay up to date component */

.about #stay-up-to-date {
    background-color: #F1FDF7;
    padding-bottom: 90px;
}

.about #stay-up-to-date .title {
    padding: 40px 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}

.about .stayUpToDate-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

/********************************
 Cards
********************************/

/* Mobile First */

.cards-wrapper {
    display: grid;
    grid-gap: 25px;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr;
}

.cards-wrapper .card {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 35px 61px;
    display: grid;
    align-items: center;
    text-align: center;
    height: 220px;
    width: 100%;
    text-decoration: none;
    color: black;
}

/* Desktop */

@media screen and (min-width: 768px) {
    .cards-wrapper {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: unset;
    }
}
