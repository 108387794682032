@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  /* Page Width */
  --page-max-width: 1440;
  --page-min-width: 300;
  /* Content Width */
  --content-max-width: 940;
  /* Background Colors */
  --background: rgba(255, 255, 255, 1);
  --background-about: rgba(241, 253, 247, 1);
  --background-get-help: rgba(247, 246, 255, 1);
  --background-give-help: rgba(255, 242, 250, 1);
  --background-donate: rgba(255, 246, 240, 1);
  /* Nav Background Color */
  --nav-background: rgba(255, 255, 255, .9);
  --nav-background-about: rgba(241, 253, 247, .9);
  --nav-background-get-help: rgba(247, 246, 255, .9);
  --nav-background-give-help: rgba(255, 242, 250, .9);
  --nav-background-donate: rgba(255, 246, 240, .9);
  /* Font Colors */
  --font-color-about: rgba(0, 158, 120, 1);
  --font-color-get-help: rgba(67, 43, 250, 1);
  --font-color-give-help: rgba(214, 16, 135, 1);
  --font-color-donate: rgba(243, 106, 29, 1);
  /* Font Sizes */
  /* 34px */
  --font-size-responsive-h1: calc(29px + (34 - 29) * ((100vw - (var(--page-min-width) * 1px)) / (var(--page-max-width) - var(--page-min-width)))); 
  /* 24px */
  --font-size-responsive-h2: calc(21px + (24 - 21) * ((100vw - (var(--page-min-width) * 1px)) / (var(--page-max-width) - var(--page-min-width)))); 
  /* 18px */
  --font-size-responsive: calc(16px + (18 - 16) * ((100vw - (var(--page-min-width) * 1px)) / (var(--page-max-width) - var(--page-min-width)))); 
  /* 14px */
  --font-size-responsive-small: calc(14px + (14 - 14) * ((100vw - (var(--page-min-width) * 1px)) / (var(--page-max-width) - var(--page-min-width)))); 
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow-x: hidden;
}

body {
  background: #ffffff;
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
