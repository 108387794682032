.give-help>.page-row:nth-of-type(odd) {
    background-color: var(--background-give-help);
}

.give-help .font-color-primary {
    color: var(--font-color-give-help);
}

/************************** 
Jumbo Title
**************************/

.give-help .jumbo-title {
    background-color: var(--background-give-help);
    color: var(--font-color-give-help);
}

.give-help .two-in-one .row-container {
    display: grid;
}

.give-help .two-in-one .text-group ul {
    margin-left: -1em;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.give-help .two-in-one .row-container>img {
    width: 380px;
    margin: auto;
}

@media screen and (min-width: 768px) {
    .give-help .two-in-one .row-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (max-width: 420px) {
    .give-help .row-container>img {
        width: 300px;
        margin: auto;
    }
}

/************************** 
Stats 
**************************/

.give-help .stats {
    background-color: var(--background-give-help);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
}

.give-help .give-help-stats {
    display: grid;
    row-gap: 40px;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
}

.give-help .give-help-stats .tile {
    display: grid;
    row-gap: 5px;
}

@media screen and (min-width: 768px) {
    .give-help .give-help-stats {
        /* grid-template-columns: repeat(4, minmax(20%, 218px)); */
        justify-content: space-between;
        padding: 0 25px;
    }
}

/* Helpful Resources/Spread */

.give-help .helpful-resources-spread {
    padding-top: 60px;
    padding-bottom: 60px;
}

/* Help Us Spread */

.give-help .helpful-resources-spread .help-us-spread .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}

.give-help .helpful-resources-spread .help-us-spread .content {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.give-help .helpful-resources-spread .help-us-spread .text-group-content .download-link {
    font-weight: bold;
    text-decoration: underline;
}

/* Helpful Resources */

.give-help .helpful-resources {
    display: grid;
    row-gap: 20px;
    margin-top: 50px;
}

.give-help .helpful-resources .header1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
}

.give-help .helpful-resources .resource-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    padding: 40px 0 20px 0;
}

.give-help .helpful-resources .resource-links {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-decoration-line: underline;
    display: grid;
    row-gap: 20px;
}

/* Grid List */

.grid-list {
    display: grid;
    grid-template-columns: 3fr 10fr;
    column-gap: 3em;
    row-gap: 2em;
    justify-items: start;
}

.grid-list>.grid-list-key {
    grid-column: 1/2;
}

.grid-list>.grid-list-value {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 1em;
    grid-column: 2/3;
}

/***************************
*     How You Can Help     *
****************************/

@media screen and (min-width: 768px) {
    .give-help .how-you-can-help .row-container .section-1 {
        grid-area: section1;
        margin-bottom: 60px;
    }
    .give-help .how-you-can-help .row-container .pulling-boxes-img {
        grid-area: img;
        padding-top: 45px;
    }
    .give-help .how-you-can-help .row-container .section-2 {
        grid-area: section2;
    }
    .give-help .how-you-can-help .row-container {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        grid-template-rows: auto;
        grid-template-areas: "section1 section1 . . ." "section1 section1 img img img" "section2 section2 section2 . .";
    }
    .give-help .how-you-can-help ul {
        padding: 0 0 0 1.5em;
    }
    .give-help .how-you-can-help .section-2 {
        max-width: 700px;
    }
}

.give-help .how-you-can-help .row-container .pulling-boxes-img {
    margin: auto;
    display: block;
}

/***********************************
* Food Security and Working Groups *
***********************************/

@media screen and (min-width: 768px) {
    .food-security-and-working-groups .row-container .section-1 {
        grid-area: section1;
    }
    .food-security-and-working-groups .row-container .people-making-donations-img {
        grid-area: img;
    }
    .food-security-and-working-groups .row-container .section-2 {
        grid-area: section2;
    }
    .food-security-and-working-groups .row-container {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: auto;
        grid-template-areas: "section1 section1 . ." "section1 section1 img img" "section2 section2 img img";
    }
    .food-security-and-working-groups .row-container .people-making-donations-img {
        width: 400px;
    }
    .food-security-and-working-groups .row-container .food-security {
        margin-bottom: 180px;
    }
}

.food-security-and-working-groups .row-container .people-making-donations-img {
    width: 100%;
}

/* Food Security */

.food-security .title {
    margin-bottom: 1.5em;
}

.food-security ol {
    padding: 0;
    counter-reset: item;
}

.food-security ol>li {
    list-style-type: none;
    counter-increment: item;
    margin: 0 0 1.5em 0;
}

.food-security ol>li:last-of-type {
    margin: 0;
}

.food-security ol>li:before {
    display: inline-block;
    width: 1em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
    content: counter(item) ".";
}

/**************************
* Help Us Spread The Word *
**************************/

.help-us-spread-the-word img.newsletter {
    max-width: 330px;
}

/*****************************
 Media Queries
*****************************/

@media screen and (min-width: 768px) {
    .give-help .helpful-resources {
        font-weight: bold;
        width: 80%;
    }
    .give-help .help .pulling-boxes-img {
        padding-top: 2em;
        display: block;
    }
    .give-help .help .content-wrapper {
        grid-column: 3/6;
    }
}