/* Layout */
#app-container {
  display: flex;
  flex-direction: column;;
  font-style: normal;
}

/* Text */
.capitalize {
  text-transform: capitalize;
}

/* Anchors */
a.link-text {
  text-decoration-line: underline;
}