/* Footer */
.footer {
    width: 100%;
    max-width: 1040px;
    padding: 0 30px;
    margin: 0 auto 20px auto;
    line-height: 28px;
}

.footer > hr {
    border-top: .5px solid grey;
}

.footer .content-wrapper .contact-info {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .footer .content-wrapper .contact-info .section {
        padding: 10px 0;
    }
}

@media screen and (min-width: 768px) {
    .footer .content-wrapper {
        display: flex;
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
    }
    .footer .content-wrapper .contact-info {
        display: flex;
        flex-direction: row;
    }
}

.footer .content-wrapper .phoneNumber {
    width: max-content;
}

/* stay connected */

.footer .stay-connected .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    padding: 0 1em 8px 0;
}

.footer .stay-connected .title>b {
    display: block;
    width: max-content;
}

.footer .stay-connected .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.footer .stay-connected .subtitle a {
    font-weight: bold;
}

/* contact info */

.footer .contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    font-style: normal;
}

.footer .contact-info .section .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    color: #7A7A7A;
}

.footer .contact-info .section .line-text {
    font-weight: 500;
    font-size: 18px;
    color: #212121;
}

.footer .contact-info .social-media-icons-wrapper a:first-of-type {
    margin-right: 10px;
}

/* Links */

.footer a {
    color: black;
}

.footer .contact-info a {
    text-decoration: none;
}