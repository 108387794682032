/****************************
 Get Help
****************************/

.get-help .font-color-primary {
    color: #432BFA;
}

.get-help .jumbo-title {
    background-color: var(--background-get-help);
    color: var(--font-color-get-help);
}

.get-help section.submit-a-request .split-wrapper {
    margin-bottom: 20px;
}

.text-group .title {
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.text-group .subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.get-help .text-box {
    padding: 40px;
}

@media screen and (min-width: 768px) {
    .get-help section.submit-a-request .text-group {
        margin-top: 30px;
    }
    .button-request-help {
        width: 50%;
    }
}

/****************************
Other Resources
****************************/

@media screen and (min-width: 768px) {
    .other-resources .text-group {
        padding-right: 30%;
    }
}

.get-help .other-resources .header1, .get-help .other-resources .header2 {
    padding: 20px 0;
}

.get-help .other-resources p {
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
}
